import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import {Typography} from '@mui/material';
import {AllHTMLAttributes} from 'react';
import {useNavigate} from 'react-router';
import {YouTubeVideo} from '../../domain/YouTubeVideo';
import {YouTubePlayButton} from '../youtube/YouTubePlayButton';

type GroupCardProps = AllHTMLAttributes<HTMLDivElement> & {
  name: string;
  memberCount: number;
  video: YouTubeVideo;
};

export const GroupCard = ({name, memberCount, video, className}: GroupCardProps): JSX.Element => {
  const navigate = useNavigate();

  const onClickAdd = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    navigate('/search', {state: {group: name}});
  };

  return (
    <div
      className={`flex rounded-3xl min-h-28 mb-4 [text-shadow:_0_0_5px_rgb(199_199_199_/_85%)] hover:cursor-pointer ${className}`}
      onClick={() => navigate('/group', {state: {group: name, video: video}})}
    >
      <div className='flex-grow p-3 text-font-tertiary'>
        <Typography variant='h6'>{name}</Typography>
        <Typography variant='caption'>{memberCount} members</Typography>
        {video.id !== undefined && (
          <Typography variant='subtitle2' className=' pt-2 flex items-center'>
            <YouTubePlayButton fontColor='tertiary' video={video} />
            {video.title}
          </Typography>
        )}
      </div>
      <div className='flex flex-col gap-3 p-3 text-right'>
        <PlaylistAddCircleIcon
          fontSize='large'
          className='text-font-tertiary'
          onClick={onClickAdd}
        />
        <ExitToAppIcon fontSize='large' className='text-font-tertiary' />
      </div>
    </div>
  );
};
