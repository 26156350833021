import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useContext} from 'react';
import {UserContext} from '../../context/UserContext';

export const BottomMenu = () => {
  const navigate = useNavigate();
  const setUserContext = useContext(UserContext)[1];

  const logout = () => {
    sessionStorage.removeItem('user');
    setUserContext(undefined);
    navigate('/login');
  };

  return (
    <BottomNavigation className='w-screen' value={1}>
      <BottomNavigationAction
        label='Groups'
        value='groups'
        icon={<GroupsIcon />}
        onClick={() => navigate('/')}
      />
      <BottomNavigationAction
        label='Add song'
        value='add song'
        icon={<PlaylistAddIcon />}
        onClick={() => navigate('/search')}
      />
      <BottomNavigationAction
        label='Logout'
        value='logout'
        icon={<LogoutIcon />}
        onClick={logout}
      />
    </BottomNavigation>
  );
};
