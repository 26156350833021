import {createContext} from 'react';
import {YouTubeVideo} from '../domain/YouTubeVideo';
import YouTube from 'react-youtube';
import {YouTubePlayerState} from '../domain/YouTubePlayerState';

export type YouTubeContextProps = {
  currentVideo: YouTubeVideo | undefined;
  youtubePlayerState: YouTubePlayerState;
  youtubePlayerRef: React.RefObject<YouTube>;
};

export const YouTubeContext = createContext([undefined, () => undefined] as [
  YouTubeContextProps | undefined,
  React.Dispatch<React.SetStateAction<YouTubeContextProps>>
]);
