import {Box, Container} from '@mui/material';
import {BottomMenu} from '../navigation/BottomMenu';
import {TopMenu} from '../navigation/TopMenu';

type MainLayoutProps = {
  bottomMenu?: boolean;
  justifyContentCenter?: boolean;
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  children: React.ReactNode;
};

export const MainLayout = ({
  bottomMenu = true,
  justifyContentCenter = false,
  alignItems,
  children
}: MainLayoutProps): JSX.Element => {
  return (
    <>
      <div className='bg-primary h-screen text-font-primary overflow-auto'>
        <TopMenu />
        <Box display='flex'>
          <Container
            sx={{
              display: 'flex',
              ...(alignItems && {alignItems}),
              flexDirection: 'column',
              ...(justifyContentCenter && {justifyContent: 'center'})
            }}
            className='pt-3 pb-16'
          >
            {children}
          </Container>
        </Box>
      </div>
      {bottomMenu && (
        <div className='fixed bottom-0 w-full'>
          <BottomMenu />
        </div>
      )}
    </>
  );
};
