import {useState, useEffect} from 'react';
import {searchYouTube} from '../clients/YouTubeClient';

export const useVideos = (term: string | undefined) => {
  const [videos, setVideos] = useState<any[] | undefined>();

  useEffect(() => {
    const fetchVideos = async () => {
      if (term) {
        const response = await searchYouTube(term);
        setVideos(response);
      }
    };
    fetchVideos();
  }, [term]);

  return {videos};
};
