import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useContext, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getMemberGroups} from '../clients/SOTDClient';
import {MainLayout} from '../components/layout/MainLayout';
import {UserContext} from '../context/UserContext';
import {MemberGroup} from '../domain/MemberGroup';
import {YouTubeVideo} from '../domain/YouTubeVideo';
import {useVideos} from '../hooks/useVideos';

export const SearchScreen = (): JSX.Element => {
  const {state} = useLocation();
  const [groups, setGroups] = useState<MemberGroup[] | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [selectedVideo, setSelectedVideo] = useState<YouTubeVideo>();
  const [userContext] = useContext(UserContext);
  const searchRef = useRef<HTMLInputElement>(null);
  const youtube = useVideos(searchTerm);

  const onClickSearch = () => {
    setSearchTerm(searchRef.current?.value);
  };

  const onClickEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchTerm(searchRef.current?.value);
    }
  };

  const onClickVideo = (video: any) => {
    setSelectedVideo({id: video.id.videoId, title: video.snippet.title});
  };

  const handleGroupChange = (event: SelectChangeEvent) => {
    setSelectedGroup(event.target.value as string);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      const sessionUser = JSON.parse(sessionStorage.getItem('user')!).email;
      const response = await getMemberGroups(userContext?.email! || sessionUser);
      setGroups(response);
    };
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state) {
      setSelectedGroup(state.group);
    }
  }, [state]);

  useEffect(() => {
    setLoading(true);
  }, [searchTerm]);

  useEffect(() => {
    setLoading(false);
  }, [youtube.videos]);

  return (
    <MainLayout>
      <div className='flex justify-between items-center pb-6'>
        <Typography variant='h6'>Submit a song</Typography>
        <CheckIcon fontSize='large' className={'text-font-primary hover:cursor-pointer'} />
      </div>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={2}
      >
        <div className='w-full'>
          <FormControl fullWidth>
            <InputLabel sx={{color: 'white'}}>Group</InputLabel>
            <Select
              labelId='group-select-label'
              id='group-select'
              label='Group'
              value={selectedGroup}
              className='text-color-primary'
              onChange={handleGroupChange}
            >
              {groups?.map((group) => (
                <MenuItem key={group.groupName} value={group.groupName}>
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className='flex items-center gap-2 w-full'>
          <TextField
            fullWidth
            inputRef={searchRef}
            id='search'
            label='Search'
            variant='outlined'
            inputProps={{onKeyUp: onClickEnter}}
          />
          <IconButton color='primary' onClick={onClickSearch}>
            <SearchIcon />
          </IconButton>
        </div>
      </Box>
      <Container className='mt-4 !p-0'>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {loading ? (
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4}>
              <CircularProgress className='fixed top-1/3 white' size={'100px'} />
            </Box>
          ) : (
            youtube?.videos?.map((video) => (
              <Box
                key={JSON.stringify(video.snippet)}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={2}
                className={`cursor-pointer ${
                  selectedVideo?.id === video.id.videoId ? 'bg-quaternary' : 'bg-secondary'
                } rounded-lg`}
                onClick={() => onClickVideo(video)}
              >
                <picture className='m-2 border border-tertiary'>
                  <source srcSet={video.snippet.thumbnails.high.url} media='(min-width: 1024px)' />
                  <source srcSet={video.snippet.thumbnails.medium.url} media='(min-width: 600px)' />
                  <img src={video.snippet.thumbnails.default.url} alt={video.snippet.title} />
                </picture>
                <Typography className='p-4'>{video.snippet.title}</Typography>
              </Box>
            ))
          )}
        </Box>
      </Container>
    </MainLayout>
  );
};
