import {AppBar, Box, Toolbar, Typography} from '@mui/material';

export const TopMenu = () => {
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position='static'>
        <Toolbar className='bg-secondary'>
          <Typography variant='h6' component='div' sx={{flexGrow: 1}} className='text-center'>
            Song of the Day
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
