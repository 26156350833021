import {Clear} from '@mui/icons-material';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {MainLayout} from '../components/layout/MainLayout';
import {TabPanel} from '../components/tabs/TabPanel';
import {YouTubePlayButton} from '../components/youtube/YouTubePlayButton';

export const GroupDetailsScreen = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [value, setValue] = useState<number>(0);
  const [members, setMembers] = useState<string[]>(['Daniel Decamp', 'Catherine Decamp']);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const onClickAdd = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    navigate('/search', {state});
  };

  return (
    <MainLayout>
      <div className='flex justify-between items-center pb-4'>
        <Typography variant='h5'>{state.group}</Typography>
        <PlaylistAddCircleIcon
          fontSize='large'
          className='text-font-primary hover:cursor-pointer'
          onClick={onClickAdd}
        />
      </div>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          variant='fullWidth'
        >
          <Tab label='Members' {...a11yProps(0)} />
          <Tab label='Song History' {...a11yProps(1)} />
          <Tab label='Submissions' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableBody>
              {members.map((member, index) => (
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {border: 0}
                  }}
                >
                  <TableCell component='th' scope='row'>
                    <Typography variant='body2'>{index + 1}</Typography>
                  </TableCell>
                  <TableCell>{member}</TableCell>
                  <TableCell>
                    <Clear
                      fontSize='small'
                      className='hover:cursor-pointer'
                      onClick={() => {
                        setMembers(members.filter((_, i) => i !== index));
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableBody>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {border: 0}
                }}
              >
                <TableCell component='th' scope='row'>
                  <YouTubePlayButton fontColor='primary' video={state.video} />
                </TableCell>
                <TableCell>{state.video.title}</TableCell>
                <TableCell>Daniel Decamp</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableBody>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {border: 0}
                }}
              >
                <TableCell component='th' scope='row'>
                  <YouTubePlayButton fontColor='primary' video={state.video} />
                </TableCell>
                <TableCell>{state.video.title}</TableCell>
                <TableCell>
                  <Clear fontSize='small' className='hover:cursor-pointer' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </MainLayout>
  );
};
