import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {Box, Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getMemberGroups} from '../clients/SOTDClient';
import {GroupCard} from '../components/card/GroupCard';
import {MainLayout} from '../components/layout/MainLayout';
import {UserContext} from '../context/UserContext';
import {MemberGroup} from '../domain/MemberGroup';

export const GroupScreen = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState<MemberGroup[] | undefined>();
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    const fetchGroups = async () => {
      const sessionUser = JSON.parse(sessionStorage.getItem('user')!).email;
      const response = await getMemberGroups(userContext?.email! || sessionUser);
      setGroups(response);
    };
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundColor = (index: number) => {
    const colors = [
      'bg-muted-primary',
      'bg-muted-secondary',
      'bg-muted-tertiary',
      'bg-muted-quaternary'
    ];
    const color = colors[index % colors.length];
    return color;
  };

  const createGroupHandler = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/group/new');
  };

  return (
    <MainLayout>
      <div className='flex justify-between items-center pb-6'>
        <Typography variant='h6'>Welcome {userContext?.displayName}</Typography>
        <GroupAddIcon
          fontSize='large'
          className='mr-1 hover:cursor-pointer'
          onClick={createGroupHandler}
        />
      </div>
      {!groups ? (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4}>
          <CircularProgress className='fixed white' size={'100px'} />
        </Box>
      ) : (
        groups.map((group, index) => (
          <GroupCard
            key={index}
            name={group.groupName}
            memberCount={group.memberCount}
            video={{id: group.songId, title: group.songTitle}}
            className={getBackgroundColor(index)}
          />
        ))
      )}
    </MainLayout>
  );
};
