import {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext';

export const RequireAuth = ({children}: {children: React.ReactNode}) => {
  const [userContext] = useContext(UserContext);
  if (!userContext && !sessionStorage.getItem('user')) {
    return <Navigate to='/login' replace />;
  }
  return <>{children}</>;
};
