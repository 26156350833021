import axios from 'axios';

const YouTubeSearch = axios.create({
  baseURL: 'https://www.googleapis.com/youtube/v3'
});

YouTubeSearch.interceptors.request.use((config) => {
  config.params = {
    part: 'snippet',
    type: 'video',
    maxResults: 5,
    key: process.env.REACT_APP_YOUTUBE_API_KEY,
    ...config.params
  };
  return config;
});

export const searchYouTube = async (term: string) => {
  const response = await YouTubeSearch.get('/search', {
    params: {
      q: term
    }
  });
  return response.data.items;
};
