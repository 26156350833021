import {useEffect, useRef, useState} from 'react';
import {isIOS} from 'react-device-detect';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import YouTube from 'react-youtube';
import './App.css';
import {RequireAuth} from './components/auth/RequireAuth';
import {UserContext} from './context/UserContext';
import {YouTubeContext, YouTubeContextProps} from './context/YouTubeContext';
import {User} from './domain/User';
import {YouTubePlayerState} from './domain/YouTubePlayerState';
import {CreateGroupScreen} from './screens/CreateGroupScreen';
import {GroupDetailsScreen} from './screens/GroupDetailsScreen';
import {GroupScreen} from './screens/GroupScreen';
import {LoginScreen} from './screens/LoginScreen';
import {SearchScreen} from './screens/SearchScreen';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginScreen />
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <GroupScreen />
      </RequireAuth>
    )
  },
  {
    path: '/group',
    element: (
      <RequireAuth>
        <GroupDetailsScreen />
      </RequireAuth>
    )
  },
  {
    path: '/search',
    element: (
      <RequireAuth>
        <SearchScreen />
      </RequireAuth>
    )
  },
  {
    path: '/group/new',
    element: (
      <RequireAuth>
        <CreateGroupScreen />
      </RequireAuth>
    )
  },
  {
    path: '*',
    element: <div>Not Found</div>
  }
]);

const App = (): JSX.Element => {
  const [userContext, setUserContext] = useState<User>();
  const [youtubeContext, setYouTubeContext] = useState<YouTubeContextProps>({
    currentVideo: undefined,
    youtubePlayerState: YouTubePlayerState.UNSTARTED,
    youtubePlayerRef: useRef<YouTube>(null)
  });

  useEffect(() => {
    if (
      isIOS &&
      [YouTubePlayerState.UNSTARTED, YouTubePlayerState.BUFFERING].includes(
        youtubeContext.youtubePlayerState
      )
    ) {
      console.log(YouTubePlayerState[youtubeContext.youtubePlayerState]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youtubeContext.youtubePlayerState]);

  return (
    <UserContext.Provider value={[userContext, setUserContext]}>
      <YouTubeContext.Provider value={[youtubeContext, setYouTubeContext]}>
        <RouterProvider router={router} />
        <YouTube
          ref={youtubeContext?.youtubePlayerRef}
          videoId={youtubeContext?.currentVideo?.id || ''}
          opts={{height: '0', width: '0', playerVars: {autoplay: 1}}}
          onStateChange={(e) =>
            setYouTubeContext({
              ...youtubeContext!,
              youtubePlayerState: e.data
            })
          }
        />
        <ToastContainer />
      </YouTubeContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
