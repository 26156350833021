import {GoogleLogin} from '@react-oauth/google';
import {useNavigate} from 'react-router-dom';
import {MainLayout} from '../components/layout/MainLayout';
import {jwtDecode} from 'jwt-decode';
import {useContext} from 'react';
import {UserContext} from '../context/UserContext';
import {User} from '../domain/User';

export const LoginScreen = () => {
  const navigate = useNavigate();
  const setUserContext = useContext(UserContext)[1];

  const onSuccess = async (response: any) => {
    const userInfo = jwtDecode(response.credential) as any;
    const user: User = {
      displayName: userInfo.name,
      email: userInfo.email
    };
    sessionStorage.setItem('user', JSON.stringify(user));
    setUserContext(user);
    navigate('/');
  };

  return (
    <MainLayout bottomMenu={false} justifyContentCenter alignItems='center'>
      <GoogleLogin
        containerProps={{className: '!fixed top-1/2'}}
        text='signin_with'
        theme='filled_black'
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
};
