import {Clear} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import {useContext, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {createGroup} from '../clients/SOTDClient';
import {MainLayout} from '../components/layout/MainLayout';
import {UserContext} from '../context/UserContext';

export const CreateGroupScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext)[0];
  const groupNameRef = useRef<HTMLInputElement>(null);
  const memberEmailRef = useRef<HTMLInputElement>(null);
  const [members, setMembers] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClickAddMember = () => {
    if (members.length < 9) {
      setMembers([...members, memberEmailRef.current?.value as string]);
      memberEmailRef.current!.value = '';
    }
  };

  const onClickEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickAddMember();
    }
  };

  const onClickCreateGroup = async () => {
    const sessionUser = JSON.parse(sessionStorage.getItem('user')!).email;
    const groupName = groupNameRef.current?.value;
    setIsLoading(true);
    if (groupName) {
      members.push(userContext?.email! || sessionUser);
      await createGroup(groupName, members);
      navigate('/');
    } else {
      toast.dark('Group name is required', {
        autoClose: 2000
      });
    }
    setIsLoading(false);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4}>
          <CircularProgress className='fixed white' size={'100px'} />
        </Box>
      ) : (
        <>
          <div className='flex justify-between items-center pb-4'>
            <Typography variant='h5'>Create Group</Typography>
            <CheckIcon
              onClick={onClickCreateGroup}
              fontSize='large'
              className='text-font-primary hover:cursor-pointer'
            />
          </div>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={2}
          >
            <TextField
              fullWidth
              inputRef={groupNameRef}
              id='groupName'
              label='Name'
              variant='outlined'
            />
            <Typography variant='body1' width='100%' className='pt-4'>
              Add members
            </Typography>
            <Typography variant='caption' width='100%'>
              Limited to 10 members (including yourself)
            </Typography>
            <div className='flex items-center gap-2 w-full'>
              <TextField
                fullWidth
                inputRef={memberEmailRef}
                id='memberEmail'
                label='Email Address'
                variant='outlined'
                inputProps={{onKeyUp: onClickEnter}}
              />
              <SendIcon
                fontSize='large'
                className='hover:cursor-pointer'
                onClick={onClickAddMember}
              />
            </div>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableBody>
                  {members.map((member, index) => (
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell component='th' scope='row'>
                        <Typography variant='body2'>{index + 1}</Typography>
                      </TableCell>
                      <TableCell>{member}</TableCell>
                      <TableCell>
                        <Clear
                          fontSize='small'
                          className='hover:cursor-pointer'
                          onClick={() => {
                            setMembers(members.filter((_, i) => i !== index));
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </MainLayout>
  );
};
