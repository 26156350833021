import axios from 'axios';
import {MemberGroup} from '../domain/MemberGroup';

const customHeaders = {
  'X-Api-Key': process.env.REACT_APP_SOTD_SERVICE_API_KEY,
  'Content-Type': 'application/json'
};

const sotdClient = axios.create({
  baseURL: process.env.REACT_APP_SOTD_SERVICE_URL,
  headers: customHeaders
});

export const getMemberGroups = async (email: string): Promise<Array<MemberGroup>> => {
  const response = await sotdClient.get(`/member/${email}`);
  return response.data;
};

export const createGroup = async (groupName: string, members: Array<string>): Promise<void> => {
  await sotdClient.post('/group', {
    groupName,
    members
  });
};

export const getMockGroups = async (): Promise<Array<any>> => {
  return [
    {
      name: 'Super Awesome Group',
      memberCount: 4,
      artist: 'The Beatles',
      songTitle: 'Hey Jude',
      videoId: 'A_MjCqQoLLA'
    },
    {
      name: 'Count me in!',
      memberCount: 5,
      artist: 'The Rolling Stones',
      songTitle: 'Paint It Black',
      videoId: 'EBUFtoC2oj4'
    },
    {
      name: 'Escape the city',
      memberCount: 3,
      artist: 'The Who',
      songTitle: 'Baba O’Riley',
      videoId: 'x2KRpRMSu4g'
    },
    {
      name: 'The best of the best',
      memberCount: 6,
      artist: 'The Beatles',
      songTitle: 'Let It Be',
      videoId: 'QDYfEBY9NM4'
    },
    {
      name: 'The 80s',
      memberCount: 4,
      artist: 'Queen',
      songTitle: 'Bohemian Rhapsody',
      videoId: 'fJ9rUzIMcZQ'
    },
    {
      name: 'The 90s',
      memberCount: 5,
      artist: 'Nirvana',
      songTitle: 'Smells Like Teen Spirit',
      videoId: 'hTWKbfoikeg'
    },
    {
      name: 'The 2000s',
      memberCount: 3,
      artist: 'The White Stripes',
      songTitle: 'Seven Nation Army',
      videoId: '0J2QdDbelmY'
    },
    {
      name: 'The 2010s',
      memberCount: 6,
      artist: 'Kendrick Lamar',
      songTitle: 'HUMBLE.',
      videoId: 'tvTRZJ-4EyI'
    }
  ];
};
