import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import {useContext, useEffect, useState} from 'react';
import {YouTubeContext} from '../../context/YouTubeContext';
import {YouTubePlayerState} from '../../domain/YouTubePlayerState';
import {YouTubeVideo} from '../../domain/YouTubeVideo';

type YouTubePlayButtonProps = {
  fontColor: 'primary' | 'secondary' | 'tertiary';
  video: YouTubeVideo;
};

export const YouTubePlayButton = ({fontColor, video}: YouTubePlayButtonProps): JSX.Element => {
  const [playButtonState, setPlayButtonState] = useState<YouTubePlayerState>(
    YouTubePlayerState.UNSTARTED
  );
  const [youtubeContext, setYouTubeContext] = useContext(YouTubeContext);

  useEffect(() => {
    if (youtubeContext?.currentVideo?.id === video.id) {
      setPlayButtonState(youtubeContext?.youtubePlayerState!);
    } else {
      setPlayButtonState(YouTubePlayerState.UNSTARTED);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youtubeContext?.youtubePlayerState]);

  const clickPlay = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setPlayButtonState(YouTubePlayerState.BUFFERING);

    if (youtubeContext?.currentVideo?.id === video.id) {
      if (
        [
          YouTubePlayerState.UNSTARTED,
          YouTubePlayerState.ENDED,
          YouTubePlayerState.PAUSED
        ].includes(playButtonState)
      ) {
        youtubeContext?.youtubePlayerRef.current?.internalPlayer.playVideo();
      } else if (playButtonState === YouTubePlayerState.PLAYING) {
        youtubeContext?.youtubePlayerRef.current?.internalPlayer.pauseVideo();
      }
    } else {
      setYouTubeContext?.({
        ...youtubeContext!,
        currentVideo: video
      });
    }
  };

  const getCurrentIcon = () => {
    if (playButtonState === YouTubePlayerState.PLAYING) {
      return (
        <PauseCircleOutlineIcon
          fontSize='large'
          className={`-ml-1 mr-1 text-font-${fontColor}`}
          onClick={clickPlay}
        />
      );
    } else if (
      [YouTubePlayerState.UNSTARTED, YouTubePlayerState.ENDED, YouTubePlayerState.PAUSED].includes(
        playButtonState
      )
    ) {
      return (
        <PlayCircleOutlineIcon
          fontSize='large'
          className={`-ml-1 mr-1 text-font-${fontColor}`}
          onClick={clickPlay}
        />
      );
    } else {
      return (
        <CircularProgress
          className={`-ml-1 mr-[10px] mb-[6px] ${fontColor === 'primary' ? 'white' : ''}`}
          size={28.8}
        />
      );
    }
  };
  return <>{getCurrentIcon()}</>;
};
